_boxes {
  ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-bottom: 4px;
  }

  li {
    margin: 0.5em 0.5em 0.25em 0.5em;
    & > div {
      margin: 0.25em 0.5em;
    }
    border-left: 1px solid rgba(black, 0.33);

    display: inline-block;
    border: 1px solid rgba(black, 0.33);
    border-radius: 3px;

    & > div {
      display: inline-block;
    }
  }

  ul li {
    background: rgba(0, 0, 0, 0.05);
  }

  ul li ul li {
    background: white;
  }

  ul li ul li ul li {
    background: rgba(0, 0, 0, 0.05);
  }

  ul li ul li ul li ul li {
    background: white;
  }
}

.tgaa-targets-or-latest-projects {
  &__view_mode {
    position: absolute;
    right: 0;
    top: 0;
    background: white;
    margin: 5px 10px;
    z-index: 1;
  }

  &__target-tree {
    &--boxes-projects {
      @extend _boxes;
    }

    &--boxes-latest-projects {
      @extend _boxes;

      ul {
        display: block;
      }
      li {
        display: list-item;
      }
      & > div {
        display: block;
      }
    }
  }
}
