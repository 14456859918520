.document-menu-page {
  &__item-container {
    display: flex;
    padding-top: 5em;
  }

  &__item {
    flex-basis: 1px;
    flex-grow: 1;
    text-align: center;
    max-width: 25%;
  }
}
