.plan-page {
  min-height: 100vh;

  background-size: 100% 1.2em;

  &__node {
    padding: 0.25em 0.75em;
    margin: 1em 0;

    font-size: 1.5rem;

    position: relative;
    background: #ffffa5;

    &--has-subtasks {
      background: #b9f8a1;

      padding-top: 0.75em;

      .plan-page__node {
        margin: 0.75em 0.5em;
      }
    }
  }
}
