.bottom-right-gray-label {
  color: #ddd;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: -0.08em;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 0.21em;
}
