.hover-toolbox {
  position: relative;

  &:hover {
    background: rgba(#3498db, 0.08);

    .hover-toolbox__toolbox {
      display: block;
    }
  }

  &__toolbox {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    background: rgba(white, 0.85);
    z-index: 1;
    border-radius: 3px;
  }
}
