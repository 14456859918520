.header {
  margin-bottom: 20px;
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: 0px 0px 4px rgba(black, 0.35);
  //background: rgba(#3498db, 0.08);
  background: #edf6fc;

  &--bottom-left {
    position: fixed;
    bottom: 0px;
    left: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    z-index: 1;
  }
}

.navigation {
  &--compact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
