.kanban {
  background: #00000008;
  min-height: 100vh;

  &__heading {
    color: #999;
    margin-bottom: 1em;
  }

  &__column {
    padding: 10px;
    margin: 10px;
  }

  &__column-heading {
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 120%;
    background: #030858;
    border-radius: 2px;
    padding: 0.3em 0.7em;
    display: inline-block;
    margin-bottom: 1.5em;
  }

  &__card {
    background: white;
    box-sizing: content-box;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-bottom: 0.8em;
    margin-right: 0.8em;
    font-family: Lato;
    padding: 0.5em 0.8em;
    display: inline-block;


    &--has-1-subcards {
      $subcardLength: 7px;
      box-shadow:
              1 * $subcardLength (1 * $subcardLength) 0 -1px #fbfbfb, 1 * $subcardLength (1 * $subcardLength) #ccc;
    }

    &--has-2-subcards {
      $subcardLength: 6px;
      box-shadow:
              1 * $subcardLength (1 * $subcardLength) 0 -1px #fbfbfb, 1 * $subcardLength (1 * $subcardLength) #ccc,
              2 * $subcardLength (2 * $subcardLength) 0 -1px #fbfbfb, 2 * $subcardLength (2 * $subcardLength) #ccc;
    }

    &--has-3-subcards {
      $subcardLength: 5px;
      box-shadow:
              1 * $subcardLength (1 * $subcardLength) 0 -1px #fbfbfb, 1 * $subcardLength (1 * $subcardLength) #ccc,
              2 * $subcardLength (2 * $subcardLength) 0 -1px #fbfbfb, 2 * $subcardLength (2 * $subcardLength) #ccc,
              3 * $subcardLength (3 * $subcardLength) 0 -1px #fbfbfb, 3 * $subcardLength (3 * $subcardLength) #ccc;
    }
  }

  &__card:nth-child(4n-1) {
    transform: rotate(-3deg);
  }

  &__card:nth-child(4n-0) {
    transform: rotate(-0deg);
  }

  &__card:nth-child(4n+1) {
    transform: rotate(-2deg);
  }

  &__card:nth-child(4n+2) {
    transform: rotate(1deg);
  }

}
