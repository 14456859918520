.bump_project {
  &__view_mode {
    position: absolute;
    right: 0;
    top: 0;
    background: white;
    margin: 5px 10px;
    z-index: 1;
  }
}
