.categorize-page {
  &__ith_of_total {
    color: rgba(black, 0.66);
    font-size: 66%;
    font-style: italic;
  }

  &__no_items_to_categorize {
    color: rgba(black, 0.66);
    font-style: italic;
  }
}
