/* PulsatingEffect.css */
@keyframes pulsate {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  75% {
    transform: scale(0.9);
    opacity: 0.85;
  }
}

.pulsating-effect {
  animation: pulsate 0.3s infinite;
}
