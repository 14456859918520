.editable-node-content {
  &__form {
    //
  }

  &__textarea {
    width: 100%;
    padding: 0 0px;
    border: none;
  }

  &__content {
    cursor: text;
  }
}
