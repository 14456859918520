@mixin subtle-top-border {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: gray;
  }
}

.bujo-month {

  &__heading {
    font-family: "Kalam";
    font-size: 130%;
  }

  &__day {
    display: flex;
  }

  &__day-number {
    width: 1.3em;
    text-align: center;
    flex-shrink: 0;

    &--first-day-of-week {
      @include subtle-top-border;
    }
  }

  &__day-of-week {
    text-align: center;
    width: 1.3em;

    flex-shrink: 0;

    &--first-day-of-week {
      @include subtle-top-border;
    }
  }

  &__text {
    flex-grow: 1;
    padding-left: 0.2em;
  }
}
