.dynalist-internal-link {
  padding: 6px !important;
  padding: 0 8px !important;
  margin: 0 3px !important;
  border: 1px solid #aaa !important;
  border-radius: 6px;
  text-decoration: none !important;
  color: inherit !important;
  background-color: #f3f3f3 !important;
}
