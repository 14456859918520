.nicer-content-fitting-text-area {
  display: block;
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(206, 212, 218);
  padding: 0.5em;

  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}
